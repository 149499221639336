import "./Presentation/Module/Dialog";

// Scroll word into view
const hash = window.location.hash;
if (hash.startsWith("#search") && hash.length > 7) {
    findSearchContent();
}

function findSearchContent() {
    const search = hash.substring(7).replaceAll("%20", " ").replace(/\s+/, " ");
    if (search.length < 1) return;
    const main = document.querySelector(".main");
    const escapedSearch = search.replace(/'/g, "\\'");

    const xpath = `//main//*[contains(translate(text(), '${escapedSearch.toUpperCase()}', '${escapedSearch.toLowerCase()}'), '${escapedSearch.toLowerCase()}')]`;
    const matchingElements = document.evaluate(
        xpath,
        main,
        null,
        XPathResult.ORDERED_NODE_ITERATOR_TYPE,
        null
    );

    const elements = [];
    let element;

    while ((element = matchingElements.iterateNext())) {
        elements.push(element);
    }

    if (typeof elements[0] === "undefined") return;

    elements.forEach((element) => {
        element.innerHTML = element.innerHTML.replace(
            new RegExp(`(${search})`, "gi"),
            `<mark>$1</mark>`
        );
    });

    const searchSection = elements[0].closest("section,article,.js-accordion");
    if (!searchSection) return;

    searchSection.scrollIntoView({
        behavior: "auto",
        block: "start",
    });

    // Special cases
    if (searchSection.matches(".js-accordion")) {
        // substitute for toggle accordion item from styleguide
        // makes for a smoother transition but also has the reason that this is a race condition
        // TODO: refactor this to a proper solution
        setTimeout(() => {
            const el = searchSection.querySelector(".js-accordion__button");
            el.click();
        }, 400);
    }
}
