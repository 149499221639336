const header = document.querySelector("header.header");
const mobileMenuButton = document.querySelector(".js-showmenu__toggle");

function calculateHeaderHeight() {
    document.documentElement.style.setProperty(
        "--header-height",
        header.offsetHeight + "px"
    );
}

document.addEventListener("alpine:init", () => {
    const Alpine = window.Alpine;
    calculateHeaderHeight();

    Alpine.data("searchDialog", (searchUrl) => ({
        open: false,
        searchTerm: "",
        lastSearchTerm: "",
        mobileMenuWasOpen: false,
        loading: false,
        count: {
            document: 0,
        },
        resultsCount: {
            document: 0,
            total: 0,
        },
        results: [],
        type: "document",
        types: ["document"],
        itemsPerPage: 10,
        currentPage: 1,
        numberOfPages: 1,
        noSearchTerm: false,
        showNotFound: false,
        button: {
            "@resize.window.passive.debounce"() {
                // Update on resize finished
                calculateHeaderHeight();
            },
            "@click"() {
                this.open = !this.open;

                if (mobileMenuButton) {
                    const mobileMenuIsOpen =
                        mobileMenuButton.classList.contains("close");

                    if (
                        mobileMenuIsOpen ||
                        (!this.open && this.mobileMenuWasOpen)
                    ) {
                        mobileMenuButton.click();
                    }

                    this.mobileMenuWasOpen = mobileMenuIsOpen;
                }
            },
        },
        fetch() {
            if (!this.searchTerm) {
                this.noSearchTerm = true;
                return;
            }
            const searchTermChanged = this.searchTerm !== this.lastSearchTerm;
            if (searchTermChanged) {
                this.currentPage = 1;
            }
            this.lastSearchTerm = this.searchTerm;
            this.noSearchTerm = false;
            this.showNotFound = false;
            this.results = [];
            this.loading = true;

            fetch(searchUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    search: this.searchTerm,
                    page: this.currentPage,
                    type: this.type,
                }),
            })
                .then((response) => {
                    if (!response.ok || response.status >= 300) {
                        this.loading = false;
                        throw new Error("Network response was not ok");
                    }
                    return response.json();
                })
                .then((data) => {
                    this.loading = false;
                    const {
                        results,
                        resultsCount,
                        numberOfPages,
                        itemsPerPage,
                        types,
                    } = data;

                    if (searchTermChanged || resultsCount[this.type] === 0) {
                        types.some((type) => {
                            if (resultsCount[type] > 0) {
                                this.type = type;
                                return true;
                            }
                            return false;
                        });
                    }

                    this.results = results;
                    this.resultsCount = resultsCount;
                    this.itemsPerPage = itemsPerPage;
                    this.numberOfPages = numberOfPages;

                    if (!results.length || !resultsCount.total) {
                        this.showNotFound = true;
                    }
                });
        },
        tag: (term) => ({
            "@click"() {
                this.searchTerm = term;
                this.fetch();
            },
            ":class"() {
                return (
                    this.searchTerm === term &&
                    "search-frequently-searched__tag--active"
                );
            },
        }),
    }));
});
